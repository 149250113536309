.DataGrid {
  height: 451px;
  width: 70%;
}

.label {
  margin-bottom: 0px;
}

.profile {
  text-align: center;
  position: relative;
  bottom: 30px;
}

.profileimages {
  width: 100px;
}

.inputSearchForm {
  display: inline-flex;
}

.inputClose {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  bottom: 5px;
  z-index: 333;
}

.searchBtn {
  background: #399282;
  border: 1px solid #399282;
  color: #fff;
  border-radius: 6px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.exportBtn_ {
  background: transparent;
  border: 1px solid #399282;
  color: #399282;
  border-radius: 6px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin-right: 10px;
}